.shop_checkout_rating {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  position: fixed;
  height: 60%;
  bottom: 0;
  right: 0;
  width: 500px;
  background: #fff;
  box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.2);
  z-index: 5;
  overflow: hidden;
  animation: showRating 0.5s ease;
  &_close {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    &_icon {
      width: 50px;
      height: 5px;
      background-color: rgb(200, 200, 200);
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_in {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
  }
  &_stars {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    margin-top: 50px;
    margin-bottom: 20px;
    & svg {
      font-size: 55px;
      color: #dadada;
      cursor: pointer;
      transition: all 0.3s;
    }
    &--active {
      & svg {
        color: #f4c52c;
        transition: all 0.3s;
      }
    }
  }
  &_review {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & label {
      text-align: center;
      width: 100%;
      font-size: 16px;
      margin-bottom: 20px;
      color: var(--dynamic-color);
      font-weight: 400;
    }
    & textarea {
      border: 1px solid #c8c8c8;
      color: #444;
      font-size: 14px;
      font-weight: 400;
      text-indent: 10px;
      padding: 12px 10px;
      cursor: pointer;
      border-radius: 6px;
      width: 80%;
      height: 80px;
      &:focus {
        outline: none;
        border: 1px solid var(--dynamic-color);
      }
    }
    & p {
      min-height: 23px;
      text-align: center;
      width: 100%;
      font-size: 25px;
      margin-bottom: 20px;
      color: green;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &_btn {
    width: 80%;
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #25262b !important;
    color: #fff;
    height: 50px !important;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 20px auto 0 auto;
    &--loading {
      background: #fff;
    }
    &:hover {
      transform: translateY(-3px);
    }
  }
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}

@media only screen and (max-width: 600px) {
  .shop_checkout_rating {
    width: 100%;
  }
}
