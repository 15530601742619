.shop_basket_btn {
  width: 70%;
  height: 50px !important;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  &--grow {
    animation: growBtn 1s;
    border-radius: 6px;
  }
  &--shrink {
    animation: shrinkBtn 1s;
    border-radius: 6px;
  }
  &_in {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.2);
    padding: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: showBasketBtn 0.3s ease;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      svg {
        font-size: 22px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
      }
    }
    &_count {
      width: 25px;
      height: 25px;
      padding: 2px;
      border-radius: 100px;
      color: #25262b;
      background: #fff;
    }
    &_name {
      color: #fff;
      margin-right: auto;
      margin-left: 10px;
    }
    &_amount {
      color: #fff;
    }
    &--dark {
      background: #fff !important;
    }
    &--empty {
      background: #f8f9fa !important;
      color: var(--dynamic-color) !important;
      border: 1px solid var(--dynamic-color) !important;
      cursor: default !important;
      p {
        color: var(--dynamic-color) !important;
        padding-right: 30px;
      }
    }
    &--active {
      background: var(--dynamic-color);
      cursor: pointer !important;
    }
    svg {
      font-size: 30px;
      color: var(--dynamic-color);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
    }
    p {
      width: calc(100% - 30px);
      font-size: 13px;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      color: #fff;
      text-transform: uppercase;
      svg {
        color: #fff;
        font-size: 18px;
        padding-bottom: 2px;
      }
    }
  }
}

@keyframes showBasketBtn {
  0% {
    opacity: 0;
    transform: translate(0, 100px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes growBtn {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(green, 0.8);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(#8db864, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(#8db864, 0);
  }
}
@keyframes shrinkBtn {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(#e74c3c, 0.9);
    background: #e74c3c !important;
  }

  70% {
    transform: scale(0.9);
    box-shadow: 0 0 0 10px rgba(#e74c3c, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(#e74c3c, 0);
  }
}
