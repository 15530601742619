.shop_account {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  &_header {
    height: 100%;
    width: 100%;
    background-color: var(--dynamic-color);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
    &--dark {
      background-color: var(--dynamic-color);
    }
    img {
      width: 250px;
      height: 150px;
      object-fit: contain;
    }
    button {
      background: #fff;
      border-radius: 100px;
      border: none;
      cursor: pointer;
      padding: 8px 16px;
      transition: all 0.3s ease;
      font-weight: 600;
      color: #25262b;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
      position: absolute;
      top: 70px;
      right: 20px;
      font-size: 12px;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_form {
    position: absolute;
    top: 30%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.22);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateY(100%);
    padding: 50px 10px;
    &--dark {
      background: #fff;
      .no_data h2 {
        color: #fff;
      }
      .shop_passcode_form_footer p {
        color: #777 !important;
      }
      .shop_account_form_title {
        color: #fff !important;
      }
      .shop_account_form_description {
        color: #fff !important;
      }
      .shop_passcode_form_call p {
        color: #fff !important;
      }
      .shop_account_form_in_list_item {
        color: #fff !important;
        svg,
        span {
          color: #fff !important;
        }
      }
      .shop_account_form_in_list_logout {
        color: #fff !important;
        svg {
          color: #fff !important;
        }
      }
    }
    &--active {
      animation: showSignUpForm 0.5s ease;
      transform: translateY(0);
    }
    &_title {
      width: 500px;
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      color: #25262b;
      padding: 10px 10px 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        svg {
          transition: all 0.3s ease;
          font-size: 22px;
          color: #25262b;
        }
        &:hover {
          span {
            svg {
              color: #25262b;
            }
          }
        }
      }
    }
    &_description {
      width: 500px;
      text-align: center;
      font-weight: 300;
      font-size: 16px;
      color: #25262b;
    }
    &_in {
      width: 500px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px 0 0 0;
      &_list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 75%;
        overflow-y: auto;
        .no_data {
          text-align: center !important;
        }
        &_item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 40px;
          font-size: 16px;
          font-weight: 300;
          color: #25262b;
          transition: all 0.3s ease;
          cursor: pointer;
          height: 150px;
          background: #f3f3f4;
          margin-bottom: 10px;
          border-radius: 20px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 0px;
          border: 1px solid lightgray;
          &:hover {
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.22);
            background: #fff;
          }
          svg {
            transition: all 0.3s ease;
            margin-right: 10px;
            font-size: 16px;
            color: #25262b;
          }
          span {
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            svg {
              transition: all 0.3s ease;
              font-size: 18px;
              color: #25262b;
            }
          }
          &:hover {
            color: #25262b;
            svg {
              color: #25262b;
            }
            span {
              svg {
                color: #25262b;
              }
            }
          }
        }
        &_logout {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: auto;
          color: #25262b;
          transition: all 0.3s ease;
          cursor: pointer;
          font-size: 14px;
          position: absolute;
          bottom: 35%;
          font-weight: 300;
          svg {
            transition: all 0.3s ease;
            color: #25262b;
            font-size: 20px;
            margin-right: 10px;
          }
          &:hover {
            color: #25262b;
            svg {
              color: #25262b;
            }
          }
        }
        &_orders {
          width: 100%;
          margin-bottom: 4px;
          padding: 5px 0;
          border: 1px solid rgba(#999, 0.1);
          border-radius: 8px;
          transition: all 0.3s ease;
          &:hover {
            background: rgba(#f3f3f4, 1);
          }
          &--no_hover {
            &:hover {
              background: #fff !important;
            }
          }
          &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            min-height: 30px;
            &_id {
              font-size: 14px;
              color: #25262b;
              font-weight: 600;
              padding-left: 10px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            &_date {
              font-size: 13px;
              color: #555;
              font-weight: 300;
              padding-left: 5px;
            }
            &_price {
              padding-right: 10px;
              margin-left: auto;
              text-align: right;
              font-size: 13px;
              color: #25262b;
              font-weight: 600;
            }
            &_icon {
              display: flex;
              align-self: center;
              justify-content: center;
              svg {
                font-size: 28px;
                padding-right: 10px;
                color: #25262b;
              }
            }
          }
          &_in {
            width: 100%;
            border-bottom: 1px solid rgba(#999, 0.1);
            padding: 8px 0;
            &:last-child {
              border-bottom: none;
            }
            &_wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            &_name {
              display: inline;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 200px;
              margin-right: auto;
              font-size: 12px;
              color: #25262b;
              font-weight: 400;
            }
            &_qty {
              margin-right: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                color: #25262b;
                font-weight: 400;
                font-size: 12px;
              }
              span {
                padding-right: 1px;
                font-size: 12px;
              }
            }
            &_price {
              padding-right: 10px;
              font-size: 12px;
              color: #25262b;
              font-weight: 400;
            }
            &_options {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              padding-top: 5px;
              &_item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                &_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-right: auto;
                  span {
                    margin-right: 5px;
                    font-size: 12px;
                    color: #25262b;
                    font-weight: 400;
                    border-radius: 6px;
                    background: #f8f9fa;
                    padding: 4px 8px;
                  }
                  p {
                    font-size: 12px;
                    color: #25262b;
                    font-weight: 400;
                  }
                }
                &_price {
                  padding-right: 10px;
                  font-size: 12px;
                  color: #25262b;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes showSignUpForm {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .shop_account_form_title {
    font-size: 22px;
    width: 100%;
  }
  .shop_account_form_description {
    width: 100%;
  }
  .shop_account_form_in_btn {
    width: 95%;
  }
  .shop_account_form {
    top: 25%;
  }
  .shop_account_form_in {
    width: 100%;
  }
  .shop_account_form_footer {
    width: 100%;
  }
  .shop_account_header button {
    top: 28px;
  }
  .shop_account_form {
    padding: 25px 10px;
  }
  .shop_account_form_in_list_logout {
    bottom: 30%;
  }
}
