.shop_category_product {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(#999, 0.2);
  overflow: hidden;
  margin-bottom: 0;
  &:nth-child(5n) {
    margin-right: 0;
  }
  &_in {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 10px 15px;
    width: 100%;
    &_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
    }
    &_footer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 160px;
    }
  }
  &_img {
    min-width: 160px;
    max-width: 160px;
    height: 160px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.5s ease;
    img {
      width: 100%;
      height: 100%;
      background-position: center;
      object-position: center;
      object-fit: cover;
      background-size: cover;
    }
  }
  &_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: 5px;
  }
  &_name {
    font-weight: 600;
    color: #25262b;
    width: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    &_icon {
      width: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      cursor: pointer;
      svg {
        font-size: 20px;
      }
    }
  }
  &_description {
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: #25262b;
    overflow-wrap: anywhere;
  }
  &_review {
    margin-right: auto;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    background: #e8e8e8;
    padding: 3px 6px;
    border-radius: 100px;
    margin-top: auto;
    &_icon {
      display: flex;
      align-self: center;
      justify-content: flex-start;
      svg {
        font-size: 16px;
        color: #25262b;
      }
    }
    &_count {
      display: flex;
      align-self: center;
      justify-content: flex-start;
      padding-left: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #25262b;
    }
  }
  &_labels {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    &_item {
      list-style: none;
      font-size: 13px;
      font-weight: 400;
      border-radius: 8px;
      padding: 5px 8px;
      text-align: center;
      margin-bottom: 5px;
      margin-right: 5px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 5px;
        font-size: 16px;
        color: var(--dynamic-color);
        transition: all 0.3s ease;
        cursor: pointer;
        height: 100%;
        text-transform: lowercase;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          transition: all 0.3s ease;
          font-size: 20px;
          color: var(--dynamic-color);
        }
        &:hover {
          transform: translateY(-3px);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_price {
    font-size: 14px;
    color: #25262b;
    font-weight: 400;
    margin: 2px;
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    &_in {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
    &_btn {
      border: none;
      width: 30px;
      height: 30px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 900;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &--add {
        background-color: var(--dynamic-color);
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.05);
        }
        svg {
          font-size: 22px;
          path {
            stroke: #fff;
          }
        }
      }
      &--remove {
        background: rgba(#e74c3c, 1);
        svg {
          font-size: 20px;
          color: #fff;
        }
      }
    }
    span {
      margin-right: 10px;
      color: #25262b;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &_basket {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid rgba(#999999, 0.2);
    animation: slideDown 0.3s ease;
    overflow: hidden;
    padding: 5px 15px;
    background-color: rgba(#999, 0.08);
    position: relative;
    &:last-child {
      border-bottom: none;
    }
    &_hidden {
      width: 120px;
      height: 100%;
      background: rgba(#e74c3c, 0.2);
      position: absolute;
      top: 0;
      right: -120px;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 25px;
        color: #e74c3c;
      }
    }
    &_main {
      width: 100%;
    }
    &_top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
    &_qty {
      color: #25262b;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      padding: 0 5px;
    }
    &_name {
      font-size: 14px;
      color: #25262b;
      margin-right: auto;
      font-weight: 400;
      flex-wrap: wrap;
      line-height: 18px;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &_options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: 22px;
        }
        span {
          margin-right: 5px;
          border: none;
          background: #25262b;
          transition: all 0.3s;
          font-size: 10px !important;
          border-radius: 12px !important;
          color: #fff;
          font-weight: 300 !important;
          height: 18px !important;
          padding: 3px 8px !important;
          text-transform: uppercase !important;
        }
      }
    }
    &_price {
      min-width: 100px;
      text-align: right;
      font-size: 14px;
      color: #25262b;
      font-weight: 400;
      padding-right: 10px;
    }
    &_btn {
      border: none;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 900;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &--add {
        background-color: var(--dynamic-color);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          path {
            stroke: #fff;
          }
        }
      }
      &--remove {
        background: rgba(#e74c3c, 0.2);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          color: #e74c3c;
        }
      }
    }
    &_bottom {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
}
