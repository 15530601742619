.shop_sign_up {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  &_header {
    height: 100%;
    width: 100%;
    background-color: var(--dynamic-color);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
    &--dark {
      background-color: var(--dynamic-color);
    }
    img {
      width: 200px;
      object-fit: contain;
    }
    button {
      background: #fff;
      border-radius: 100px;
      border: none;
      cursor: pointer;
      padding: 8px 16px;
      transition: all 0.3s ease;
      font-weight: 600;
      color: var(--dynamic-color);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
      position: absolute;
      top: 70px;
      right: 25px;
      font-size: 12px;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_form {
    position: absolute;
    top: 30%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.22);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateY(100%);
    padding: 50px 10px;
    &--dark {
      background: #fff;
      .shop_passcode_form_footer p {
        color: #777 !important;
      }
      .shop_sign_up_form_title {
        color: #fff !important;
      }
      .shop_sign_up_form_description {
        color: #fff !important;
      }
      .shop_passcode_form_call p {
        color: #fff !important;
      }
      .shop_sign_up_form_footer {
        color: #fff;
      }
      .shop_sign_up_form_in_btn:hover {
        background: #999;
      }
    }
    &--active {
      animation: showSignUpForm 0.5s ease;
      transform: translateY(0);
    }
    &_title {
      width: 100%;
      text-align: center;
      font-weight: 800;
      font-size: 32px;
      color: #25262b;
      padding-bottom: 5px;
      text-transform: capitalize;
    }
    &_description {
      width: 100%;
      text-align: center;
      font-weight: 300;
      font-size: 14px;
      color: #25262b;
    }
    &_in {
      width: 100%;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 14px;
        font-weight: 600;
        background: #fff;
        border-radius: 100px;
        padding: 5px 12px;
        border: 1px solid rgba(#999, 0.2);
        width: 300px;
        height: 40px;
        margin-bottom: 10px;
        &:hover,
        &:active {
          background: rgba(#999, 0.1);
        }
        &_input {
          background: #fff;
          border-radius: 100px;
          border: none;
          width: calc(100% - 35px);
          height: 100%;
          font-size: 14px;
          font-weight: 600;
          color: var(--dynamic-color);
          text-indent: 5px;
        }
        &_back {
          margin: 10px 0 15px 0;
          font-size: 13px;
          color: var(--dynamic-color);
          font-weight: 400;
          cursor: pointer;
          width: 100%;
          text-align: center;
        }
        &_icon {
          width: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          svg {
            font-size: 22px;
            background: #fff;
            color: var(--dynamic-color);
            border-radius: 100px;
          }
        }
        &--nohover {
          animation: fromBottom30px 0.7s ease;
          &:hover,
          &:active {
            background: #fff !important;
          }
        }
        img {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }
    }
    &_footer {
      text-align: center;
      padding: 0 10px 10px 10px;
      color: #777;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      width: 350px;
    }
  }
}

.shop_sign_up .drop_down_menu {
  z-index: 11 !important;
}
.shop_sign_up .overlay {
  background: red !important;
  z-index: 10 !important;
}
@keyframes showSignUpForm {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fromBottom30px {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .shop_sign_up_form_title {
    font-size: 22px;
  }
  .shop_sign_up_form_in_btn {
    width: 95%;
  }
  .shop_sign_up_form {
    top: 30%;
  }
  .shop_sign_up_form_footer {
    width: 100%;
  }
  .shop_sign_up_header button {
    top: 28px;
    right: 10px;
  }
  .shop_sign_up_form {
    padding: 25px 10px;
  }
}
