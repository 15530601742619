.shop_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--dynamic-color);
  flex-direction: column;
  padding: 0 15px;
  height: 45px;
  &--dark {
    background: #fff !important;
  }
  &_in {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_nav {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        cursor: pointer;
        background-color: rgba(203, 210, 246, 0.5);
        border-radius: 100px;
        padding: 5px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        svg {
          color: #fff;
          font-size: 20px;
        }
      }
    }
    &_logo {
      display: flex;
      align-self: center;
      justify-content: flex-start;
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
      }
    }
    &_search {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      margin-left: auto;
      background-color: #f3f3f4;
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s ease;
      svg {
        transition: all 0.3s ease;
        font-size: 20px;
        color: #25262b;
      }
      &_modal {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        width: calc(100% - 500px);
        opacity: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2;
        &--short {
          width: 80%;
          right: 0;
          left: unset;
          transform: translate(0, 0);
          z-index: 3;
        }
        &--open {
          opacity: 1;
          animation: openModal 0.3s ease;
        }
        &_header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 10px;
          h2 {
            font-size: 18px;
            cursor: pointer;
          }
          svg {
            font-size: 25px;
            cursor: pointer;
            margin-right: 10px;
            color: var(--dynamic-color);
          }
        }
        &_wrapper {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 10px;
          background: #fff;
          svg {
            font-size: 25px;
            cursor: pointer;
            margin-right: 10px;
            color: var(--dynamic-color);
          }
        }
        &_title {
          padding: 8px 10px 4px 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 18px;
          font-weight: 600;
        }
        &_input {
          padding: 8px 10px;
          border: none;
          border-radius: 100px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          height: 40px;
          background-color: #f3f3f4;
          svg {
            font-size: 20px;
            color: var(--dynamic-color);
            margin: 0 10px;
            transition: all 0.3s ease;
          }
          input {
            display: block;
            background: transparent;
            width: 100%;
            height: 100%;
            color: var(--dynamic-color);
            border: none;
            background-clip: padding-box;
            box-shadow: none;
            font-size: 14px;
            &:focus,
            &:focus-visible,
            &:hover {
              outline: none;
            }
            &::placeholder {
              color: #f3f3f4;
              font-weight: 400;
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: #f3f3f4;
              font-weight: 400;
            }
          }
        }
        &_in {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          flex-wrap: wrap;
          overflow-y: auto;
          background: #fff;
          h6 {
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            color: var(--dynamic-color);
            text-transform: uppercase;
            padding: 10px 20px 0 20px;
          }
          h3 {
            font-weight: 600;
            font-size: 14px;
            width: 100%;
            color: var(--dynamic-color);
            padding: 10px 20px 0 20px;
          }
          &_btn {
            font-size: 14px;
            width: 100%;
            text-align: left;
            color: var(--dynamic-color);
            padding: 10px 20px;
            font-weight: 500;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            border-top: 1px solid rgba(#999, 0.2);
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:last-child {
              border-bottom: 1px solid rgba(#999, 0.2);
            }
            &--first {
              margin-top: 15px;
            }
            svg {
              font-size: 20px;
              color: #25262b;
              margin-right: 10px;
            }
            &:hover {
              background: #f3f3f4;
            }
          }
          &_loading {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &_title {
            width: 100%;
            font-size: 22px;
            font-weight: 600;
            color: #25262b;
            padding: 20px 0 5px 0;
            text-align: center;
          }
          &_description {
            text-align: center;
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            color: #25262b;
          }
        }
        &--dark {
          .shop_header_in_search_modal_wrapper {
            background: #25262b !important;
          }
          .shop_header_in_search_modal_in_loading {
            background: #25262b !important;
          }
          .shop_header_in_search_modal_in_title {
            color: #fff !important;
          }
          .shop_header_in_search_modal_in_description {
            color: #fff !important;
          }
          .shop_header_in_search_modal_wrapper svg {
            color: #777 !important;
          }
          .shop_header_in_search_modal_in {
            background: #25262b !important;
          }
          .shop_category_product_name,
          .shop_category_product_price,
          .shop_category_product_actions span,
          .shop_category_product_basket_qty,
          .shop_category_product_basket_price,
          .shop_category_product_basket_name {
            color: #fff !important;
          }
        }
      }
    }
    &_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        cursor: pointer;
        background-color: #f3f3f4;
        border-radius: 100px;
        padding: 5px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        svg {
          color: #25262b;
          font-size: 20px;
        }
      }
      &_lng {
        width: 30px;
        height: 30px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-size: cover;
          border: 2px solid #fff;
          border-radius: 100px;
        }
      }
      &_modal {
        position: absolute;
        z-index: 0;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        box-shadow:
          0 50px 100px rgba(50, 50, 93, 0.1),
          0 15px 35px rgba(50, 50, 93, 0.15),
          0 5px 15px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        opacity: 0;
        transform: translateY(-100px);
        width: 80px;
        overflow: hidden;
        &--show {
          animation: showModal 0.3s ease;
          transform: translateY(0);
          opacity: 1;
          z-index: 3;
        }
        &_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
          padding: 4px 8px;
          transition: all 0.3s ease;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #f3f3f4;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #25262b;
          }
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            background-size: cover;
          }
        }
      }
    }
    &_menu {
      position: fixed;
      top: 0;
      right: 0;
      transform: translateX(-100%);
      background: #6a30f5;
      width: 350px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 3;
      box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
      &_header {
        width: 100%;
        background: #fff;
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        border-radius: 0 0 6px 6px;
        height: 70px;
        &_img {
          width: 180px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            object-fit: contain;
            object-position: center;
          }
        }
      }
      &_in {
        width: 100%;
        padding: 20px 20px 0 20px;
        height: 100%;
        &_welcome {
          width: 100%;
          margin-bottom: 30px;
          p {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
          }
          h2 {
            color: #fff;
            font-size: 22px;
            font-weight: 600;
          }
        }
        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          transition: all 0.3s ease;
          cursor: pointer;
          margin: 20px 0;
          svg {
            font-size: 20px;
            margin-right: 10px;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #cecdcd;
            svg {
              color: #cecdcd;
            }
          }
        }
        &_wrapper {
          width: 100%;
          height: 100%;
          svg {
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              color: #cecdcd;
            }
          }
          &_no_data {
            font-size: 14px;
            color: #fff;
            padding: 10px 0;
            width: 100%;
            text-align: center;
            margin-top: 100px;
          }
          &_nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            svg {
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              transition: all 0.3s ease;
              &:hover {
                color: #cecdcd;
              }
            }
            h3 {
              margin-left: auto;
              color: #fff;
              font-size: 14px;
              font-weight: 400;
            }
          }
          &_list {
            width: 100%;
            margin-top: 20px;
            overflow-y: auto;
            height: calc(100% - 20px - 20px - 20px);
          }
          &_auth {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            &_item {
              list-style: none;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #e0e0e0;
              border-radius: 100px;
              width: 45px;
              height: 45px;
              transition: all 0.3s ease;
              cursor: pointer;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
              }
              &--active {
                background-color: rgba(203, 210, 246, 0.5);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                svg {
                  color: #fff !important;
                }
              }
              &:hover {
                background-color: rgba(203, 210, 246, 0.5);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                svg {
                  color: #fff !important;
                }
              }
              svg {
                font-size: 25px;
                color: #6a30f5;
                margin-bottom: 0;
                transition: all 0.3s ease;
              }
            }
            &_input {
              width: 100%;
              border: none;
              background: transparent;
              height: 45px;
              cursor: text;
              margin: 20px 0 20px 0;
              color: #fff;
              font-size: 14px !important;
              border-bottom: 1px solid transparent;
              transition: all 0.3s ease;
              &_animation {
                animation: fromAboveInput 0.5s ease;
              }
              &::placeholder {
                color: #fff;
                font-weight: 400;
                opacity: 1;
                font-size: 13px !important;
              }
              &::-ms-input-placeholder {
                color: #fff;
                font-weight: 400;
                font-size: 13px !important;
              }
              &:hover,
              &:focus {
                outline: none;
                border-bottom: 1px solid rgba(203, 210, 246, 0.5);
              }
            }
            &_btn {
              background: transparent;
              border: 1px solid rgba(203, 210, 246, 0.5);
              z-index: 1;
              width: 100%;
              height: 45px;
              color: #fff;
              border-radius: 8px;
              padding: 10px 25px;
              font-weight: 400;
              font-size: 14px;
              cursor: pointer;
              transition: all 0.3s ease;
              position: relative;
              display: inline-block;
              outline: none;
              overflow: hidden;
              text-transform: lowercase;
              &:after {
                position: absolute;
                content: '';
                width: 0;
                height: 100%;
                top: 0;
                right: 0;
                z-index: -1;
                background-color: rgba(203, 210, 246, 0.5);
                transition: all 0.3s ease;
              }
              &:hover {
                background: transparent;
                color: #fff;
                border: 1px solid #fff;
              }
              &:hover:after {
                left: 0;
                width: 100%;
              }
              &:active {
                top: 2px;
              }
            }
            &_title {
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              padding: 10px 0;
              line-height: 22px;
              margin-bottom: 30px;
              margin-top: 20px;
            }
          }
        }
      }
      &_footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        p {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            color: #fff;
          }
        }
      }
      &_lng {
        margin-top: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 20px;
        &_description {
          font-size: 12px;
          margin-bottom: 8px;
          color: #fff;
          font-weight: 300;
          width: 100%;
          text-align: center;
        }
        .drop_down_in {
          border: 1px solid #fff;
        }
        .drop_down_in_lng input {
          background: transparent;
          color: #fff;
        }
        &_drop_down {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 10px;
          cursor: pointer;
          height: 30px;
          transition: all 0.3s ease;
          &:hover {
            h3 {
              color: #fff !important;
            }
          }
          &_img {
            width: 20px;
            height: 20px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 100% !important;
              background-size: cover;
              object-fit: cover;
              margin: 0 !important;
            }
          }
          &_name {
            font-size: 13px;
            font-weight: 400;
            color: #525f7f;
            transition: all 0.3s ease;
            margin-left: 10px;
          }
        }
      }
      &--close {
        animation: closeMenu 0.5s ease;
        transform: translateX(-100%);
      }
      &--open {
        animation: openMenu 0.5s ease;
        transform: translateX(0);
      }
    }
  }
  &_wifi_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    &--dark {
      .shop_header_wifi_modal_in {
        background: #25262b !important;
        h3,
        h2 {
          color: #fff !important;
        }
        h3 {
          border-bottom: 1px solid #fff !important;
        }
      }
    }
    &_in {
      width: 400px;
      background: #25262b !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
      padding: 20px 0;
      &--active {
        animation: showModal 0.3s ease;
        transform: translateY(0);
      }
      h2 {
        color: #fff;
        font-size: 14px;
        text-align: center;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(#fff, 1);
        margin-bottom: 10px;
        font-weight: 400;
      }
      p {
        font-size: 13px;
        font-weight: 300;
        color: #fff;
        text-align: center;
        width: 100%;
        line-height: 22px;
        padding: 0 10px;
      }
      &_actions {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        padding: 0 10px;
        &_btn {
          transition: all 0.3s;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.055em;
          padding: 8px 10px;
          width: 80px;
          cursor: pointer;
          border-radius: 6px;
          margin-top: 5px;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            transform: translateY(-3px);
          }
          &--cancel {
            background: transparent;
            border: none;
            color: #32325d;
            font-weight: 500;
          }
          &--submit {
            background: #e74c3c;
            border: 1px solid #e74c3c;
            color: #fff;
          }
        }
      }
    }
  }
}
.shop_drop_down {
  background: #6a30f5 !important;
  border: 1px solid #fff !important;
}
.shop_header_in_menu_lng_drop_down_name {
  color: #fff;
}
.shop_header_in_menu_lng_drop_down {
  &:hover {
    background: #fff !important;
    .shop_header_in_menu_lng_drop_down_name {
      color: #25262b !important;
    }
  }
}
.shop_header_in_menu_lng_drop_down.drop_down_menu__item--active {
  background: #fff !important;
  .shop_header_in_menu_lng_drop_down_name {
    color: #25262b;
  }
}
.button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.gmail {
  background-color: #dc4e41;
  color: #fff;
}
.apple {
  background-color: #25262b;
  color: #fff;
}

@keyframes fadeInContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes openMenu {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes closeMenu {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes openModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showModal {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes showAuth {
  0% {
    transform: translate(-50%, 150px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
@keyframes fromAboveInput {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 900px) {
  .shop_header_in_search_modal {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .shop_header_in_menu {
    width: 90%;
  }
  .shop_header_wifi_modal {
    width: 95%;
  }
}
