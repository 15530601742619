.navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s;
  overflow: hidden;
  font-family: inherit;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;
  background: #fff;
  width: 280px;
  border-right: 1px solid rgba(36, 41, 48, 0.08);
  &--open {
    transform: translateX(0);
  }
  &_in {
    position: relative;
    background: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &_minimize {
      position: absolute;
      top: 28px;
      left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.1);
        svg {
          color: #25262b;
        }
      }
      svg {
        font-size: 18px;
        color: #25262b;
      }
    }
    &_close {
      position: absolute;
      top: 43px;
      right: 10px;
      cursor: pointer;
      svg {
        font-size: 22px;
        color: #25262b;
      }
    }
    &_fullscreen {
      margin-top: auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 12px;
      label {
        font-size: 14px;
        font-weight: 400;
        color: #25262b;
      }
      & input[type='checkbox'] {
        -webkit-appearance: none;
        background: #f8f9fa;
        width: 60px;
        padding: 0 !important;
        height: 25px;
        border: none !important;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        position: relative;
        left: 0;
        cursor: pointer;
      }
      & input:checked[type='checkbox'] {
        background: #f8f9fa;
      }
      & input[type='checkbox']:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: rgba(#999, 0.5);
        transition: all 0.5s ease;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        margin-left: 3px;
      }
      & input:checked[type='checkbox']::before {
        left: 34px;
        background: #25262b;
      }
      & input[type='checkbox'] {
        -webkit-appearance: none;
        background: #f8f9fa;
        width: 60px;
        padding: 0 !important;
        height: 25px;
        border: none !important;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        position: relative;
        left: 0;
        cursor: pointer;
      }
      & input:checked[type='checkbox'] {
        background: #f8f9fa;
      }
      & input[type='checkbox']:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: rgba(#999, 0.5);
        transition: all 0.5s ease;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        margin-left: 3px;
      }
      & input:checked[type='checkbox']::before {
        left: 34px;
        background: #25262b;
      }
    }
    &_lng {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 150px;
      &_drop_down {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px;
        cursor: pointer;
        height: 30px;
        transition: all 0.3s ease;
        &:hover {
          h3 {
            color: #25262b !important;
          }
        }
        &_img {
          width: 20px;
          height: 20px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 100% !important;
            background-size: cover;
            object-fit: cover;
            margin: 0 !important;
          }
        }
        &_name {
          font-size: 14px;
          font-weight: 400;
          color: #25262b;
          transition: all 0.3s ease;
          margin-left: 10px;
        }
      }
    }
    img {
      width: 100px;
      margin-left: 12px;
      margin-top: 12px;
    }
    & ul {
      width: 100%;
      display: block;
      margin-top: 10px;
    }
    & li {
      width: 100%;
      text-transform: capitalize;
    }
    &_burger {
      img {
        width: 100px;
        margin-left: 10px;
      }
      svg {
        display: none;
        font-size: 22px;
        color: #000;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    img {
      width: 100px;
      margin-left: 10px;
    }
  }
  &_list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5px;
    height: 40px;
    transition: all 0.3s ease;
    margin: 5px 0;
    & a {
      text-decoration: none !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #25262b;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      transition: all 0.3s ease;
      svg {
        transition: all 0.3s ease;
      }
      &:hover,
      &:active,
      &:focus {
        background: #f3f3f4;
        border-radius: 4px;
        color: #25262b;
        outline: none;
        .navigation__list-icon {
          svg {
            color: #25262b !important;
          }
        }
      }
    }
    &_icon {
      font-size: 3rem;
      margin: 0 10px;
      display: flex;
      align-items: center;
      transition: all 1s;
      &--live {
        transition: all 1s;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        box-shadow: 0 0 0 0 rgba(#4cd964, 1);
        margin-left: auto;
        margin-right: 10px;
        animation: pulse 2s infinite;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 100px;
          background: #e74c3c;
        }
      }
    }
    &--active,
    &:hover {
      .navigation_list_icon {
        cursor: pointer;
        transition: all 0.3s ease;
        svg {
          color: #25262b !important;
        }
      }
      & a {
        position: relative;
        text-decoration: none;
        background: #f3f3f4;
        border-radius: 6px;
        cursor: pointer;
        color: #25262b;
        transition: all 0.3s ease;
      }
    }
  }
}
.drop_down_menu__item--active {
  .navigation_in_lng_drop_down_name {
    color: #25262b !important;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#e74c3c, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(#e74c3c, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#e74c3c, 0);
  }
}
@media only screen and (max-width: 1024px) {
  .navigation {
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.5s;
    &--open {
      transform: translateX(0);
    }
  }
  .navigation_in_burger {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    img {
      margin-top: 0;
    }
    svg {
      display: block;
      margin-top: 0;
      margin-left: 13px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .drop_down_in_lng input {
    font-size: 12px;
  }
  .navigation_in_lng_drop_down_name {
    font-size: 12px;
  }
}
