.auth {
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  h1 {
    font-weight: 600;
    margin: 0;
    font-size: 21px;
    color: #fff;
    text-transform: initial;
  }
  h2 {
    text-align: center;
    font-size: 21px;
    color: #25262b;
    padding-bottom: 5px;
  }
  p {
    font-size: 15px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
    color: #fff;
  }
  span {
    font-size: 15px;
    color: #25262b;
    font-weight: 300;
  }
  button {
    border-radius: 100px;
    border: 1px solid #25262b;
    background-color: #25262b;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 15px 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: translateY(-3px);
    }
  }
  button:active {
    transform: scale(0.95);
  }
  button:focus {
    outline: none;
  }
  button.ghost {
    background-color: #f8f9fa;
    border-color: #ced4db;
    color: #25262b;
    &:hover {
      background: #fff;
      border: 1px solid #fff;
      color: #25262b;
    }
  }
  form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }
  input {
    background-color: #f8f9fa;
    border: none;
    padding: 16px 15px;
    margin: 8px 0;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
  }
  &_error {
    font-size: 12px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    color: #e74c3c;
  }
  &_forget_btn {
    transition: all 0.3s ease !important;
    color: #25262b;
    font-size: 12px;
    text-decoration: none;
    margin: 15px 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &_terms {
    margin: 5px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    &_checkbox {
      width: unset !important;
    }
    input[type='checkbox'] {
      margin: 5px 10px 5px 0 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      display: inline-block;
      width: 18px !important;
      height: 18px !important;
      color: #fff;
      padding: 1px;
      background-clip: content-box;
      border: 2px solid rgb(200, 200, 200);
      background-color: rgb(200, 200, 200);
      border-radius: 3px;
    }
    input[type='checkbox']:checked {
      background-color: #25262b;
    }
    &_txt {
      color: #25262b;
      font-size: 12px;
    }
    &_btn {
      color: #25262b;
      font-size: 12px;
      font-weight: 600;
      margin-left: 5px;
      transition: all 0.3s ease !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .auth_sign_up_btn_mobile {
    transition: all 0.3s ease !important;
    color: #25262b;
    font-size: 12px;
    text-decoration: none;
    margin: 0 0 15px 0;
    cursor: pointer;
    display: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    width: 70%;
    height: 90%;
    &_modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
      input {
        width: 450px;
      }
      button {
        width: 250px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        border: 1px solid #25262b;
        background-color: #25262b;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        padding: 15px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-top: 10px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        .loading {
          padding: 0 !important;
        }
      }
      img {
        width: 200px;
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.5px;
        margin: 20px 0;
        text-align: center;
      }
    }
  }
  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    img {
      width: 200px;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .login-container {
    left: 0;
    width: 50%;
    opacity: 1;
    z-index: 5;
  }
  .container.right-panel-active .login-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
  }
  .social-container {
    margin: 20px 0;
    svg {
      font-size: 2.5rem;
      margin-top: 2px;
      color: #25262b;
      transition: all 0.3s ease;
    }
  }
  .social-container .social {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    cursor: pointer;
    width: 40px;
    &:hover {
      svg {
        color: #25262b;
      }
    }
  }
  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }
  .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }
  .overlay {
    background: #25262b;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #25262b;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  .overlay-left {
    transform: translateX(-20%);
  }
  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }
  .overlay-right {
    right: 0;
    transform: translateX(0);
  }
  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }
  .loading {
    padding: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .auth .container {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .auth .overlay-container {
    display: none;
  }
  .auth .login-container {
    width: 100%;
  }
  .auth .container {
    width: 90%;
  }
  .auth form {
    padding: 0 25px;
  }
  .auth button {
    width: 50%;
    padding: 12px 10px;
  }
  .auth .auth_sign_up_btn_mobile {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .auth button {
    width: 100%;
  }
  .auth .container_modal input,
  .auth .container_modal button {
    width: 90%;
  }
  .auth .container_modal {
    padding: 0 10px;
  }
}
